import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import flowers from "./Vector.svg";
import couple2 from "./couple-2.png";
import couple3 from "./couple-3.png";
import ReservationForm from "./Formulario-reserva-de-habitaciones_Boda-Nicole&Damaso.docx";
import ourStory1 from "./our-story-1.png";
import ourStory2 from "./our-story-2.png";
import ourStory3 from "./our-story-3.png";
import ourStory4 from "./our-story-4.png";
import lavenderPixel from "./LAVENDER_PIXEL.png";
import redPixel from "./RED_PIXEL.png";
import hotel from "./hotel.jpeg";
import dressCodeMan from "./dress-code-man.png";
import dressCodeWoman from "./dress-code-woman.png";
import heroImage from "./hero-image.png";
import hero01 from "./hero01.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSidenav } from "./hooks/use-sidenav";
import Sidenav from "./components/Sidenav";
import { useParallax } from "react-scroll-parallax";
import ScrollToTop from "./components/ScrollToTop";
import { useImagesCloudfare } from "./hooks/use-images-cloudfare";

function App() {
  const parallaxData = useParallax({
    speed: 0.5,
  });
  const { sideNavOpen, toggleSideNav } = useSidenav();

  const {images} = useImagesCloudfare();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <ScrollToTop />

      <Sidenav sideNavOpen={sideNavOpen} toggleSideNav={toggleSideNav} />

      <div
        className="App bg-bgColor min-h-screen font-primaryFont"
        ref={parallaxData.ref}
      >
        <Header toggleSideNav={toggleSideNav} />

        <main className="w-full pt-16 flex flex-col items-center justify-center">
          <div className="container pb-8">
            <section className="font-primaryFont text-primaryColor flex justify-center flex-col">
              <div>
                <h3 className="text-center text-lg text-secondaryColor">
                  Celebremos juntos el amor de
                </h3>
                <h1 className="text-center md:text-8xl text-7xl">Damaso <span className="block md:inline-block">& Nicole</span></h1>
                <p className="text-center text-lg pt-3 pb-10 text-secondaryColor">
                  09 Noviembre, 2024
                </p>
              </div>

              <div className="mx-auto hidden md:block">
                <img src={heroImage} alt="placeholder" />
              </div>

              <div className="mx-auto md:hidden block">
                <img src={hero01} alt="placeholder" />
              </div>

              <div className="py-14 md:py-20 w-20 mx-auto">
                <hr className="text-primaryColor" />
              </div>
            </section>

            <section
              className="font-primaryFont text-3xl text-primaryColor flex justify-center flex-col"
              id="invitation"
            >
              <div className="text-center text-4xl md:text-5xl">
                <h3>Detalles del Evento</h3>
              </div>

              <div className="py-14">
                <div className="rounded-2xl w-3/4 md:w-1/2 mx-auto py-12 bg-white shadow-md text-xl md:text-2xl text-center">
                  <p className="font-light font-secondaryFont text-secondaryColor">
                    <span className="pb-4 font-normal font-primaryFont text-primaryColor text-2xl md:text-3xl">Cuándo</span>
                    <span className="block pt-3">Sábado</span> 09 de Noviembre de 2024
                  </p>
                </div>
              </div>
            </section>

            <section className="relative py-8 md:py-24">
            <div className="flex absolute justify-center -z-10  items-center w-full h-full overflow-hidden top-0 left-0">
                <img src={flowers} className="w-3/4 md:w-2/4" alt="flowers" />
              </div>
            <div className="font-primaryFont w-full h-full top-0 left-0 flex items-center justify-center text-3xl text-primaryColor">
                <div className="grid md:w-1/2 grid-cols-1 gap-8 md:grid-cols-2">
                  <div className="text-center">
                    <h4 className="text-2xl pb-5">Ceremonia</h4>
                    <span className="font-secondaryFont text-secondaryColor font-light text-6xl">7:00 p.m.</span>
                    <div className="justify-center text-center">
                <span className="block pt-4 font-secondaryFont text-secondaryColor font-light text-xl">Parroquia Universitaria Santísima Trinidad</span>
                    <a href="https://maps.app.goo.gl/rFeFPeVEhxKESEfg6?g_st=iw" target="_blank" rel="noreferrer" className="font-secondaryFont text-xl text-tertiaryColor hover:underline font-light">Ver ubicación </a>
                </div> 
                  </div>
                  <div className="text-center">
                    <h4 className="text-2xl pb-5">Recepción</h4>
                    <span className="font-secondaryFont text-secondaryColor font-light text-6xl">8:30 p.m.</span>      
                    <div className="justify-center text-center">
                <span className="block pt-4 font-secondaryFont text-secondaryColor font-light text-xl">Hotel Intercontinental, Salón Kennedy</span>
                    <a href="https://maps.app.goo.gl/vdpXdkChcGFTyfkU8" target="_blank" rel="noreferrer" className="font-secondaryFont text-xl text-tertiaryColor hover:underline font-light">Ver ubicación </a>
                </div>               
                  </div>
                </div>
              </div>
              


            </section>
          </div>

          <section className="w-full mt-14 bg-primaryColor text-white font-primaryFont text-center py-16 px-8 text-3xl">
            <article>
            Encontré al que buscaba mi alma... Lo abracé y no lo solté.
            </article>
            <cite className="text-end font-secondaryFont font-light text-xl tracking-widest not-italic"> Cantares 3:4 </cite>
          </section>

          <section className="w-full hidden md:block">
            <img src={couple2} alt="placeholder" className="w-full" />
          </section>

          <section className="w-full md:hidden block">
            <img src={couple3} alt="placeholder" className="w-full" />
          </section>

          <section
            className="font-primaryFont w-full flex flex-col items-center justify-center"
            id="story"
          >
            <div className="container grid grid-cols-1 md:grid-cols-2 py-20">
              <div className="md:py-12 pt-10 pb-16 flex justify-center">
                <img src={ourStory1} alt="placeholder" className="hidden md:block" />
                <img src={ourStory3} alt="placeholder" className="md:hidden block" />
              </div>

              <div className="flex items-start flex-col justify-center text-xl text-primaryColor">
                <h3 className="text-7xl mb-8 md:px-0 px-6">Nuestra Historia</h3>
                <p className="mb-4 md:px-0 px-6 font-secondaryFont text-secondaryColor font-light leading-relaxed">
                  Nuestra historia comenzó sin buscarla. Lo que inició como una
                  simple amistad, se convirtió sin que ninguno imaginara el
                  destino que nos esperaba, en este amor profundo que hoy
                  compartimos.
                </p>
                <p className="mb-4 md:px-0 px-6 font-secondaryFont text-secondaryColor font-light leading-relaxed">
                  Todo comenzó con esos pequeños gestos: llamadas para
                  despertarnos cuando el trabajo se volvía agotador, mensajes
                  preguntando ‘¿Bajaste a comer?’, ‘¿Desayunaste?’, o un simple
                  ‘¿Necesitas ayuda con algo?’. Eran esos detalles inesperados,
                  como enviar algo para picar o una capa de agua para
                  protegernos del frío, los que nos fueron uniendo.
                </p>
                <p className="mb-4 md:px-0 px-6 font-secondaryFont text-secondaryColor font-light leading-relaxed">
                  Comenzamos a compartir comidas, a cultivar una amistad
                  sincera, a contar nuestras aventuras personales y a confiar el
                  uno en el otro con esos secretos y miedos que pocos conocen.
                  Poco a poco, construimos una base de confidencia, empatía y
                  cariño. Nos convertimos en los mejores amigos, en esas
                  personas que se apoyan incondicionalmente, que se conocen en
                  profundidad y que no sienten vergüenza alguna de caminar
                  juntos.
                </p>
              </div>
            </div>

            <div className="container grid grid-cols-1 md:grid-cols-2 md:pb-20 pb-0">
              <div className="flex items-start flex-col justify-center text-xl text-primaryColor">
                <h3 className="text-7xl mb-8 md:px-0 px-6">
                  Así es como comenzó nuestra historia…
                </h3>
                <p className="mb-4 md:px-0 px-6 font-secondaryFont text-secondaryColor font-light leading-relaxed">
                  Pero no todo ha sido perfecto y romántico. Como cualquier
                  pareja, hemos tenido nuestros problemas, días grises y
                  conflictos, tanto internos como externos. Sin embargo, en
                  lugar de alejarnos, estos desafíos nos ayudaron a crecer
                  juntos, desarrollando madurez y aprendiendo a resolver
                  nuestras diferencias. Aunque no hemos cambiado por completo,
                  hemos acogido con amor las áreas que necesitaban mejora,
                  asegurándonos de que esas dificultades no dañen lo que hemos
                  construido.
                </p>
                <p className="mb-4 md:px-0 px-6 font-secondaryFont text-secondaryColor font-light leading-relaxed">
                  Hemos pasado por mucho, y a través de esas experiencias, hemos
                  aprendido que estamos hechos el uno para el otro, y que, sin
                  importar los problemas, la distancia o el tiempo, siempre
                  estaremos ahí para apoyarnos.
                </p>
              </div>

              <div className="md:py-8 pt-8 pb-0 flex justify-center">
              <img src={ourStory2} alt="placeholder" className="hidden md:block" />
              <img src={ourStory4} alt="placeholder" className="md:hidden block" />
              </div>
            </div>
            <div className="w-full md:py-32 py-24 relative">
              <div
                className="absolute top-0 left-0 w-full h-full z-10"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgba(255,255,255,.60) 5%, transparent, transparent 75% )",
                }}
              ></div>
              <div
                className="absolute top-0 left-0 w-full h-full z-10"
                style={{
                  backgroundImage:
                    "linear-gradient(to left, rgba(255,255,255,.60) 5%, transparent, transparent 75%)",
                }}
              ></div>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                showDots={false}
                infinite={true}
                arrows={true}
              >
                {images.map((photo, index) => (
                  <div key={index} className="md:mr-6 relative md:h-[750px] h-[550px] gap-0">
                    <img
                      src={`https://imagedelivery.net/jduCsX5nqp1J2LzEgU6_Qw/${photo.id}/public`}
                      className="absolute rounded-md top-0 left-0 w-full h-full object-cover  "
                      alt="placeholder"
                      referrerPolicy="no-referrer"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </section>

          <section className="w-full bg-[#FFE2E4] text-[#9E6C64] font-primaryFont py-20 text-3xl">
            <div className="container mx-auto grid gap-8 grid-cols-1 md:grid-cols-2 text-center md:text-left">
              <h2 className="md:text-6xl text-4xl px-3">
                ¿Nos acompañas a celebrar nuestro amor?
              </h2>
              <div className="flex justify-center md:justify-end items-center">
              <a  href="https://forms.gle/GYN4egT2xjPQBFVr8"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-[#9E6C64] text-center md:w-1/2 w-3/4 md:ml-auto text-[#FFE2E4] py-4 px-8 rounded-full font-secondaryFont font-semibold text-xl">
                  Confirmar Asistencia
                </a>
              </div>
            </div>
          </section>

          <section
            className="w-full bg-primaryColor font-primaryFont py-28"
            id="weeding-list"
          >
            <div className="container mx-auto flex flex-col">
              <h3 className="md:text-6xl text-4xl text-white pb-4 text-center">
                Lista de regalos
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-12">
                <div className="flex flex-col text-center gap-4 items-center">
                  <img
                    src="https://listaderegalos.casacuesta.com/assets/images/273/logo.svg"
                    alt="placeholder"
                    className="bg-white p-4 rounded-md w-52 h-52 object-contain"
                  />
                  <a
                    href="https://listaderegalos.casacuesta.com/Event/DamasoDamian-BenildaNicole?utm_source=share"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white underline text-xl"
                  >
                    Ver lista
                  </a>
                </div>
                <div className="flex flex-col text-center gap-4 items-center">
                  <img
                    src="https://popularenlinea.com/_catalogs/masterpage/popularenlinea/shared/images/BPD-logo.png"
                    alt="placeholder"
                    className="bg-white p-4 rounded-md w-52 h-52 object-contain"
                  />
                  <button
                    onClick={() => {
                      const bankAccount = document.getElementById(
                        "bank-account-nicole"
                      );
                      bankAccount.classList.toggle("hidden");
                    }}
                    className="text-white underline text-xl"
                  >
                    Ver cuenta
                  </button>
                  <ul className="text-white font-secondaryFont font-light text-lg hidden"
                    id="bank-account-nicole"
                  >
                    <li>831178421</li>
                    <li>Cuenta Corriente DOP</li>
                    <li>Benilda Nicole Urbaez</li>
                    <li>Nicole9408@hotmail.com</li>
                    <li>402-2230191-9</li>
                  </ul>
                </div>
                <div className="flex flex-col text-center gap-4 items-center">
                  <img
                    src="https://popularenlinea.com/_catalogs/masterpage/popularenlinea/shared/images/BPD-logo.png"
                    alt="placeholder"
                    className="bg-white p-4 rounded-md w-52 h-52 object-contain"
                  />
                  <button
                    onClick={() => {
                      const bankAccount = document.getElementById(
                        "bank-account-damaso"
                      );
                      bankAccount.classList.toggle("hidden");
                    }}
                    className="text-white underline text-xl"
                  >
                    Ver cuenta
                  </button>
                  <ul
                    className="text-white font-secondaryFont font-light text-lg hidden"
                    id="bank-account-damaso"
                  >
                    <li>816633747</li>
                    <li>Cuenta Corriente USD</li>
                    <li>Damaso Valdez</li>
                    <li>damasovaldez16@gmail.com</li>
                    <li>001-1926218-6</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="w-full font-primaryFont py-28 text-3xl bg-[#F2F3F0] text-primaryColor">
            <div className="container mx-auto">
              <h3 className="md:text-6xl text-7xl text-center">Elegancia Pastel</h3>
              <p className="text-center md:py-8 pt-6 pb-10 md:px-0 px-6 text-xl font-secondaryFont text-secondaryColor font-light">
              Para armonizar con la atmósfera de nuestro día especial, les invitamos a
              vestir con elegancia en tonos suaves. <br/>¡Estamos seguros de que todos lucirán increíbles!
              </p>
              <div className="grid w-1/2 mx-auto grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col text-center items-center gap-4">
                  <img
                    src={dressCodeMan}
                    alt="placeholder"
                    className="w-80 border-8 border-white rounded-3xl bg-white"
                  />
                  <p className="text-2xl">
                    Hombres
                  </p> 
                  <span className="text-center md:py-0 text-xl font-secondaryFont text-secondaryColor font-light">
                    Atuendo formal, incorporando detalles en tonos pastel.</span>
                </div>

                <div className="flex flex-col text-center items-center gap-4">
                  <img
                    src={dressCodeWoman}
                    alt="placeholder"
                    className="w-80 border-8 border-white rounded-3xl bg-white"
                  />
                  <p className="text-2xl">
                    Mujeres
                  </p>
                  <span className="text-center md:py-0 text-xl font-secondaryFont text-secondaryColor font-light">
                  Vestidos largos en colores pastel.</span>
                </div>
              </div>
            </div>
          </section>

          <section className="w-full text-primaryColor font-primaryFont py-28 text-3xl">
            <div className="container mx-auto md:gap-32 gap-12 items-center grid grid-cols-1 md:grid-cols-2">
              <div className="text-center md:text-left md:pr-16 md:pl-0 px-6">
                <h3 className="text-6xl ">Quédate la <br/>noche entera</h3>
                <p className="text-xl py-8 font-secondaryFont font-light leading-relaxed text-secondaryColor">
                Queremos que disfruten al máximo nuestro gran día. Por esta razón, hemos asegurado tarifas especiales en el Hotel Intercontinental
                para que, si así lo desean, puedan reservar su habitación y descansar después de la celebración.
                </p>
                <a href={ReservationForm} target="_blank" rel="noreferrer" className="bg-primaryColor font-secondaryFont font-semibold text-xl text-white py-4 px-16 rounded-full">
                Reservar habitación
                </a>
              </div>
              <div>
                <img src={hotel} alt="placeholder" className="md:rounded-2xl" />
              </div>
            </div>
          </section>

          <section className="w-full relative bg-[#FFE2E4] text-[#9E6C64] font-primaryFont pt-20 pb-28 text-3xl">
            <img
              src={redPixel}
              alt="lavender pixel"
              className="absolute top-0 left-0 z-0 hidden md:block"
            />
            <img
              src={lavenderPixel}
              alt="red pixel"
              className="absolute bottom-0 left-0 z-0 md:block hidden"
            />
            <img
              src={lavenderPixel}
              alt="red pixel"
              className="absolute top-0 right-0 z-0 hidden md:block"
            />
            <img
              src={redPixel}
              alt="lavender pixel"
              className="absolute bottom-0 right-0 rotate-180 z-0 hidden md:block"
            />

            <div className="container mx-auto text-center z-10">
              <h3 className="text-6xl text-center">¡Agrega Canciones!</h3>
              <p className="text-xl pt-8 pb-16 font-secondaryFont font-light leading-relaxed w-3/4 mx-auto">
              La música es una parte esencial de nuestro día especial, y nos encantaría que formen parte de ella. Añadan sus canciones favoritas a nuestra playlist colaborativa para
              que podamos disfrutar juntos.
              </p>
              <a
                href="https://open.spotify.com/playlist/3pDYjZ0ZGLZ7IUCWHVi8A4?si=An2TVTSGTmyK3X-NHo4Xyg&pt=4ec3431f2884351addca6b0a62ce5ddf&pi=e-OqSgUrkHSVOp"
                target="_blank"
                rel="noreferrer"
                className="bg-[#9E6C64] text-xl text-[#FFE2E4] py-4 px-16 rounded-full font-secondaryFont font-semibold"
              >
                Únete a la playlist
              </a>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
}

export default App;
